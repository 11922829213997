import {
  Flex,
  Box,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { debounce, isEqual } from 'lodash'
import { FiSearch, FiPlus, FiX } from 'react-icons/fi'

import numeral from 'numeral'
import { useState, useRef, useMemo } from 'react'
import useSWR from 'swr'
import { companyRecordAtom } from '../../atoms'
import { CreateBoatProductForm } from '../../components/partials'
import {
  ThemedButton,
  ThemedImagesAtRow,
  ThemedSelect,
  ThemedTable,
} from '../../components/shared'
import { fetcher, httpPost } from '../../fetchers'
import {
  AWSQueryOutput,
  BoatProduct,
  QueryVariables,
  ThemedSelectItem,
} from '../../interfaces'
import { useBoatTypes } from '../../hooks'
import ThemedFormHelperMessage from '../../components/shared/ThemedFormHelperMessage'
import { BASE_URL } from '../../config'

const columns = [
  'Image',
  'Product Name',
  'Brand Company',
  'Model Year',
  // 'isEBC',
  // 'EBC Status',
  'Published/Unpublished',
  'Starting Price',
  'Boat Type',
  'Length',
  'Propulsion Type',
  'Certified Flag',
  'Source Product Code',
  'ID',
]

const BoatProductPage: React.FC = () => {
  const { boatTypes } = useBoatTypes()
  const [companyRecord] = useAtom(companyRecordAtom)
  const [filter, setFilter] = useState<Record<string, any>>({})
  const [defaultValues, setDefaultValues] = useState<Record<string, any>>({})
  const [checks, setChecks] = useState([false])
  const [hasChecks, setHasChecks] = useState(false)
  const [approvalMode, setApprovalMode] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const ref = useRef<HTMLInputElement>(null)
  const [boatProductKeyword, setBoatProductKeyword] = useState('')
  const toast = useToast()
  const tableRef = useRef<any>(null)

  const boatTypeOptions = [{ value: 'All', label: 'All' }, ...boatTypes]

  const [variables, setVariables] = useState<QueryVariables>({
    api: '',
    first: 10,
    after: null,
    savedCursor: [null],
    currentPage: 1,
  })

  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure()

  const {
    data: res,
    mutate,
    error,
  } = useSWR(
    [
      `/company/${companyRecord.record?.companyId}/boat-products?currentPage=${variables.currentPage}&first=${variables.first}${
        boatProductKeyword
          ? `&boatProductKeyword=${encodeURIComponent(boatProductKeyword)}`
          : ''
      }`,
      filter,
    ],
    fetcher
  )

  const isLoading = !error && !res
  const records = (res?.data as AWSQueryOutput)?.Items?.map(i => {
    const c = i as BoatProduct
    return {
      ...c,
      id: c.boatProductId,
      certifiedFlag: c.certifiedFlag ? 'Yes' : 'N/A',
      description: `${c.description?.substr(0, 19)}${
        c.description?.length >= 19 ? '...' : ''
      }`,
      image: (
        <ThemedImagesAtRow
          fileNameHashed={
            c?.fileNameHashed
              ? c?.fileNameHashed
              : c?.basicGallery?.[0]?.fileNameHashed ||
                c?.gallery?.[0]?.fileNameHashed
          }
        />
      ),
      brandCompany: companyRecord.record?.name,
      startingPrice: numeral(c.startingPrice).format('$0,.00'),
      length: `${
        Math.floor(Number(c.length)) ? `${Math.floor(Number(c.length))} ft` : ''
      } ${
        Number(
          Number(
            (Number(c?.length) - Math.floor(Number(c?.length))) * 12
          ).toFixed(2)
        )
          ? `${Number(
              Number(
                (Number(c?.length) - Math.floor(Number(c?.length))) * 12
              ).toFixed(2)
            )} in`
          : ''
      }`,
    }
  })

  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure()

  const onClose = () => {
    onModalClose()
    setDefaultValues({})
  }

  const onSort = (name: string) => {
    setVariables(v => ({
      ...v,
      currentPage: 1,
    }))

    if (
      name === 'Product Name' ||
      name === 'Brand Company' ||
      name === 'EBC Status'
    ) {
      setFilter({
        ...filter,
        sortBy: name,
        sortOrder:
          filter.sortOrder &&
          filter.sortOrder === 'asc' &&
          filter.sortBy === name
            ? 'desc'
            : 'asc',
      })
    } else {
      setFilter({
        ...filter,
        sortBy: name,
        sortOrder:
          filter.sortOrder &&
          filter.sortOrder === 'desc' &&
          filter.sortBy === name
            ? 'asc'
            : 'desc',
      })
    }
  }

  const handleNext = () => {
    setVariables(v => ({
      ...v,
      currentPage: v.currentPage! + 1,
    }))
  }

  const handlePrev = () => {
    setVariables(v => ({
      ...v,
      currentPage: v.currentPage! - 1,
    }))
  }

  const onRowClick = (row: any) => {
    const record = res?.data?.Items?.find(
      (d: BoatProduct) => d.boatProductId === row.id
    )

    setDefaultValues({
      ...record,
      boatType: { label: record.boatType, value: record.boatType },
      hullMaterial: { label: record.hullMaterial, value: record.hullMaterial },
      fuelType: { label: record.fuelType, value: record.fuelType },
      modelYear: { label: record.modelYear, value: record.modelYear },
      otherHullColor1:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[0] : '',
      otherHullColor2:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[1] : '',
      otherHullColor3:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[2] : '',
      otherHullColor4:
        record?.otherHullColor?.length > 0 ? record.otherHullColor[3] : '',
      propulsionType: {
        label: record?.propulsionType,
        value: record?.propulsionType,
      },
      status: { label: record?.status, value: record?.status },
    })
    onOpen()
  }

  const onApprovalClick = async () => {
    try {
      setSubmitting(true)

      const forCloneIds = checks.filter(Boolean)

      const forCloneData = forCloneIds
        .map(id =>
          (res?.data as AWSQueryOutput)?.Items?.find(
            r => r.boatProductId === String(id)
          )
        )
        .map((item: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { boatProductId, image, ...rest } = item ?? {
            boatProductId: '',
            productName: '',
            image: '',
            cloneCount: 0,
            status: '',
            enhancedBoatCard: false,
          }

          const cloneCounter = rest.cloneCount ? rest.cloneCount + 1 : 1
          const nameSplit = rest.productName.split(' ')
          const cloneCounterPresent =
            nameSplit[nameSplit.length - 1].includes('(') &&
            nameSplit[nameSplit.length - 1].includes(')')

          const modifiedProductName =
            cloneCounterPresent && !rest.isCloned
              ? `${nameSplit.slice(0, -1).join('')} (${cloneCounter})`
              : `${rest.productName} (${cloneCounter})`

          return {
            ...rest,
            productName: modifiedProductName,
            published: false,
            isCloned: true,
            cloneCount: 0,
            status: rest.enhancedBoatCard === false ? '' : 'Pending EBC',
            originalProductName: rest.productName,
            originalBoatProductId: boatProductId,
          }
        })

      await Promise.all(
        forCloneData.map(async item => {
          const response = await httpPost({
            url: `${BASE_URL}/boat-product/create`,
            body: item,
          })

          if (response?.data) {
            const forUpdate: any = (res?.data as AWSQueryOutput)?.Items?.find(
              r => r.boatProductId === item.originalBoatProductId
            )

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { image, ...restForUpdate } = forUpdate

            const itemForUpdate = {
              ...restForUpdate,
              cloneCount: restForUpdate?.cloneCount
                ? restForUpdate?.cloneCount + 1
                : 1,
              contactEmail: restForUpdate?.contactEmail
                ? restForUpdate?.contactEmail
                : '',
              contactFirstName: restForUpdate?.contactFirstName
                ? restForUpdate?.contactFirstName
                : '',
              contactLastName: restForUpdate?.contactLastName
                ? restForUpdate?.contactLastName
                : '',
              agreedTOS: restForUpdate?.agreedTOS
                ? restForUpdate?.agreedTOS
                : false,
              futureShows: restForUpdate?.futureShows
                ? restForUpdate?.futureShows
                : {
                    Nashville: false,
                    Chicago: false,
                    Atlanta: false,
                    Minneapolis: false,
                    'New York': false,
                    Louisville: false,
                    Miami: false,
                    'New England': false,
                    'Atlantic City': false,
                    Northwest: false,
                  },
              hullColor: restForUpdate?.hullColor
                ? restForUpdate?.hullColor
                : '',
              hullMaterial: restForUpdate?.hullMaterial
                ? restForUpdate?.hullMaterial
                : '',
              otherHullColor: [
                restForUpdate?.otherHullColor1
                  ? restForUpdate?.otherHullColor1
                  : '',
                restForUpdate?.otherHullColor2
                  ? restForUpdate?.otherHullColor2
                  : '',
                restForUpdate?.otherHullColor3
                  ? restForUpdate?.otherHullColor3
                  : '',
                restForUpdate?.otherHullColor4
                  ? restForUpdate?.otherHullColor4
                  : '',
              ],
              propulsionManufacturer: restForUpdate?.propulsionManufacturer
                ? restForUpdate?.propulsionManufacturer
                : '',
              maxHorsePower: restForUpdate?.maxHorsePower
                ? restForUpdate?.maxHorsePower
                : 0,
              maxFuelCapacity: restForUpdate?.maxFuelCapacity
                ? restForUpdate?.maxFuelCapacity
                : 0,
              maxWeightCapacity: restForUpdate?.maxWeightCapacity
                ? restForUpdate?.maxWeightCapacity
                : 0,
              maxPassengerCapacity: restForUpdate?.maxPassengerCapacity
                ? restForUpdate?.maxPassengerCapacity
                : 0,
              maxBridgeClearance: restForUpdate?.maxBridgeClearance
                ? restForUpdate?.maxBridgeClearance
                : 0,
              numberOfEngines: restForUpdate?.numberOfEngines
                ? restForUpdate?.numberOfEngines
                : 0,
              boatWeight: restForUpdate?.boatWeight
                ? restForUpdate?.boatWeight
                : 0,
              draft: restForUpdate?.draft ? restForUpdate?.draft : 0,
              beam: restForUpdate?.beam ? restForUpdate?.beam : 0,
              enhancedBoatCard: restForUpdate?.enhancedBoatCard
                ? restForUpdate?.enhancedBoatCard
                : false,
              enhancedDescription: restForUpdate?.enhancedDescription
                ? restForUpdate?.enhancedDescription
                : '',
              fuelType: restForUpdate?.fuelType ? restForUpdate?.fuelType : '',
              youtubeUrl: restForUpdate?.youtubeUrl
                ? restForUpdate?.youtubeUrl
                : '',
              brandListingUrl: restForUpdate?.brandListingUrl
                ? restForUpdate?.brandListingUrl
                : '',
              gallery: restForUpdate?.gallery ? restForUpdate?.gallery : [],
              basicGallery: restForUpdate?.basicGallery
                ? restForUpdate?.basicGallery
                : [],
            }

            await httpPost({
              url: `${BASE_URL}/boat-product/update`,
              body: itemForUpdate,
            })

            mutate().then(() => {
              toast({
                title: `${item.originalProductName} has been cloned succesfully.`,
                status: 'success',
                duration: 1500,
                isClosable: true,
              })

              setChecks([false])
              setHasChecks(false)
              tableRef.current.reset()
            })
          } else {
            toast({
              title:
                'An error has occurred upon processing your request. Please check if all required fields have been accomplished.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
            tableRef.current.reset()
            onCloseConfirmModal()
            setSubmitting(false)
          }
        })
      )

      mutate().then(() => {
        setChecks([false])
        setHasChecks(false)
        tableRef.current.reset()
      })

      onCloseConfirmModal()
      setSubmitting(false)
    } catch (err) {
      toast({
        title: 'There were errors processing your request.',
        status: 'error',
        duration: 1500,
        isClosable: true,
      })
      tableRef.current.reset()
      onCloseConfirmModal()
      setSubmitting(false)
    }
  }

  const handleSetQueryPageSize = (arg: number) => {
    setVariables({ ...variables, first: arg, currentPage: 1 })
  }

  const changeHandler = (event: any) => {
    setBoatProductKeyword(event.target.value)
    setVariables(v => ({
      ...v,
      currentPage: 1,
      savedCursor: [null],
    }))
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [])

  return (
    <>
      <Box pb="171px" position="relative">
        <Flex
          justifyContent="space-between"
          mt="54px"
          w="calc(calc(100vw - 245px) - 302px)"
          ml="51px"
        >
          <Box>
            <Text as="span" fontSize="32px" fontWeight="600">
              Add Boats
            </Text>
            <Text
              as="span"
              display="block"
              fontSize="13px"
              color="darkGray"
              maxW="60ch"
            >
              Please add your new model year boats to the system by clicking on
              the + Create button and following the instructions.
            </Text>
          </Box>
          <ThemedButton
            height="55px"
            width="158px"
            type="button"
            onClick={onOpen}
            leftIcon={<FiPlus color="white" fontSize="22px" />}
            isDisabled={isLoading || !boatTypes.length}
          >
            <Text as="span" ml="8px">
              Create
            </Text>
          </ThemedButton>
        </Flex>
        <Flex
          ml="51px"
          mt="47px"
          alignItems="center"
        >
          <Box w="300px" mr="1rem">
            <Text
              as="span"
              display="block"
              fontSize="13px"
              color="darkGray"
              mb="4px"
            >
              Boat Product
            </Text>
            <InputGroup size="md" maxW="sm">
              <InputLeftElement
                pointerEvents="none"
                color="gray.500"
                mt="4px"
                ml="3px"
              >
                <FiSearch />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search"
                backgroundColor="white"
                onChange={debouncedChangeHandler}
                ref={ref}
                h="50px"
              />
              {boatProductKeyword && (
                <InputRightElement
                  color="gray.500"
                  cursor="pointer"
                  mt="4px"
                  mr="3px"
                  onClick={() => {
                    setBoatProductKeyword('')
                    ref.current!.value = ''
                    setVariables(v => ({
                      ...v,
                      savedCursor: [null],
                      currentPage: 1,
                    }))
                  }}
                >
                  <FiX />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>
          <Box minW="200px">
            <Text
              as="span"
              display="block"
              fontSize="13px"
              color="darkGray"
              mb="4px"
            >
              Boat Type
            </Text>

            <ThemedSelect
              id="boatType"
              options={boatTypeOptions}
              isSearchable={false}
              defaultValue={boatTypeOptions[0]}
              maxWidthOptions={220}
              hasShadow
              color="#718096"
              onChange={(e: ThemedSelectItem) => {
                if (!isEqual(filter, { ...filter, boatType: e.value })) {
                  setVariables(v => ({
                    ...v,
                    savedCursor: [null],
                    currentPage: 1,
                    after: null,
                  }))
                  if (e.value === 'All') {
                    setFilter({})
                  } else {
                    setFilter(prev => ({
                      ...prev,
                      boatType: e.value,
                    }))
                  }
                }
              }}
            />
          </Box>

          {hasChecks && (
            <>
              <Button
                size="md"
                onClick={() => {
                  onOpenConfirmModal()
                  setApprovalMode('')
                }}
                minW="100px"
                colorScheme="blue"
                marginRight={4}
              >
                <Text as="span">Clone</Text>
              </Button>
            </>
          )}
        </Flex>
        <ThemedTable
          // @ts-ignore
          ref={tableRef}
          id="boatProductTable"
          columns={columns}
          rows={records}
          isLoading={isLoading}
          onRowClick={onRowClick}
          disableNext={
            Number(variables.currentPage) * Number(variables.first || 0) >
            res?.data?.TotalItems
          }
          disablePrev={Number(variables.currentPage) === 1}
          handleNext={handleNext}
          handlePrev={handlePrev}
          w="calc(100% - 200px)"
          mt="24px"
          allowMultipleChecks
          allowClone
          setChecks={setChecks}
          setHasChecks={setHasChecks}
          onSort={onSort}
          setQueryPageSize={handleSetQueryPageSize}
        />
        <Flex
          w="calc(calc(100vw - 245px) - 292px)"
          justifyContent="end"
          mb="20px"
          ml="51px"
        >
          <ThemedFormHelperMessage>
            For questions, please contact showservice@nmma.org
          </ThemedFormHelperMessage>
        </Flex>
        <CreateBoatProductForm
          isOpen={isOpen}
          onClose={onClose}
          mutate={mutate}
          companyId={companyRecord.record?.companyId}
          isCertified={companyRecord.record?.nmmaCertified}
          defaultValues={defaultValues}
        />
      </Box>

      <Modal
        isOpen={isOpenConfirmModal}
        onClose={() => {
          if (!isSubmitting) {
            onCloseConfirmModal()
          }
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p="0px" minW="500px">
          <ModalHeader>
            <Text as="span" fontWeight="600" fontSize="20px">
              {approvalMode}
            </Text>
          </ModalHeader>
          <ModalBody paddingRight="32px">
            <Text as="span" fontSize="16px" display="block">
              Are you sure to proceed?
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="end">
            <Button
              size="sm"
              onClick={() => {
                onCloseConfirmModal()
              }}
              mr="12px"
              isDisabled={isSubmitting}
              minW="100px"
            >
              <Text as="span">Cancel</Text>
            </Button>
            <Button
              size="sm"
              onClick={onApprovalClick}
              isDisabled={isSubmitting}
              minW="100px"
              colorScheme="blue"
            >
              {isSubmitting ? (
                <Spinner color="gray.500" size="sm" />
              ) : (
                <Text as="span">Confirm</Text>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default BoatProductPage
